// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import { IonicVue } from '@ionic/vue';
import '@popperjs/core';
import BootstrapVue3 from 'bootstrap-vue-3'
import { createI18n } from 'vue-i18n';
import VueApexCharts from 'vue3-apexcharts';
import VueObserveVisibility from 'vue-observe-visibility';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import firebaseMessaging from './utils/Firebase.js'

require('vue-ionicons/ionicons.css')
require('typeface-lato')

require('leaflet/dist/leaflet.css');
require('leaflet.markercluster/dist/MarkerCluster.css');
require('leaflet.markercluster/dist/MarkerCluster.Default.css');

/* Theme variables */
import './theme/variables.css';
//@ts-ignore
import eventBus from "vue3-eventbus"

const i18n = createI18n({
    // legacy: false,
    locale: localStorage.getItem('locale') ?? 'fr',
    messages: {
        "en": {
            "Guest entered": "Guest entered",
            "Loading": "Loading",
            "car": "car",
            "boat": "boat",
            "bicycle": "bicycle",
            "person": "person",
            "bus": "bus",
            "motorcycle": "motorcycle",
            "live": "Live",
            "grace_period": "Grace period",
            "grace_period_description": "The grace period allow visitors to exit without having to pay if they are within the period. (In minutes)",
            "max_usages": "Max usages",
            "reboot": "Reboot",
            "reboot_device": "Reboot device",
            "are_you_sure_reboot_device": "Are you sure you wish to restart the device <strong>{name}</strong>?",
            "MaxEntrancesReached": "Maximum entries reached",
            "has already reached max entrances": "has already reached max entrances",
            "DoesNotExists": "Does not exists",
            "unable to find access card": "Unable to find access card",
            "has_subscription": "Has subscription",
            "today": "Today",
            "license": "License",
            "expiration": "Expiration",
            "system_uptime": "Uptime",
            "total_entrances": "Total entrances",
            "total_exits": "Total exits",
            "total_customers": "Total customers",
            "total_users": "Total users",
            "total_guests": "Total guests",
            "capacity": "Capacity",
            "view_all": "View all",
            "view": "View",
            "inside": "Inside",
            "user_entrances": "User entrances",
            "user_exits": "User exits",
            "customer_entrances": "Customer entrances",
            "customer_exits": "Customer exits",
            "guest_entrances": "Guest entrances",
            "guest_exits": "Guest exits",
            "traffic_overview": "Traffic overview",
            "most_active_customers": "Most active customers",
            "devices_overview": "Devices overview",
            "most_active_users": "Most active users",
            "statistics_overview": "Statistics overview",
            "Tag(s)": "Tag(s)",
            "tags": "Tags",
            "tag": "Tag",
            "no_tag_found": "No tag found",
            "add_tag": "Add a tag",
            "edit_tag": "Edit a tag",
            "save_tag": "Save tag",
            "are_you_sure_delete_tag": "Are you sure you want to delete this tag?",
            "settings": "Settings",
            "general": "General",
            "users": "Users",
            "guest": "Guest",
            "alerts": "Alerts",
            "mails": "Mails",
            "signout": "Sign out",
            "connected": "Connected",
            "disconnected": "Disconnected",
            "open": "Open",
            "opening": "Opening",
            "closed": "Closed",
            "closing": "Closing",
            "opened": "Opened",
            "modify": "Modify",
            "all": "All",
            "no_device_found": "No device found",
            "Entrance gate": "Entrance gate",
            "Exit gate": "Entrance gate",
            "try_connecting_device": "Try connecting a device on the same network or add it manually.",
            "name": "Name",
            "brand": "Brand",
            "model": "Model",
            "type": "Type",
            "ip_addr": "IP Addr",
            "mac_addr": "MAC Addr",
            "status": "Status",
            "state": "State",
            "devices": "Devices",
            "device": "Device",
            "dashboard": "Dashboard",
            "events": "Events",
            "customers": "Customers",
            "guests": "Visitors",
            "entries": "Entries",
            "statistics": "Statistics",
            "open_gate": "Open Gate",
            "close_gate": "Close Gate",
            "SELECT": {
                "ok": "OK",
                "cancel": "CANCEL"
            },
            "cancel": "Cancel",
            "none": "None",
            "overview": "Overview",
            "save_config": "Save configuration",
            "configuration": "Configuration",
            "signals": "Signals",
            "save_signals": "Save signals",
            "output_signal": "Output signal",
            "input_signal": "Input signal",
            "keep_gate_open": "Keep the gate open",
            "gate_opened": "Gate opened",
            "gate_closed": "Gate closed",
            "security_loop_triggered": "Security loop is triggered",
            "presence_loop_triggered": "A vehicle is detected",
            "presence": "presence",
            "search_here": "Search here...",
            "output": "Output",
            "input": "Input",
            "relay": "Relay",
            "device_modified": "Device modified",
            "saved_configuration_successfully": "Saved configuration successfully.",
            "device_modification_error": "Device modification error",
            "error": "Error",
            "saved_signals_successfully": "Saved signals successfully.",
            "gate_opening": "Gate opening",
            "gate_closing": "Gate closing",
            "gate_successfully_opening": "Gate is successfully opening.",
            "gate_successfully_closing": "Gate is successfully closing.",
            "gate_error": "Gate error",
            "cannot_open_gate": "Unable to open the gate currently",
            "cannot_close_gate": "Unable to close the gate currently",
            "description": "Description",
            "date_at": "Date at",
            "critical": "Critical",
            "important": "Important",
            "information": "Information",
            "no_alert_found": "No alert found",
            "try_connecting_device_or_configure_system": "Try connecting a device or configuring the system.",
            "email": "Email",
            "validation_date": "Validation date",
            "expiration_date": "Expiration date",
            "confirm": "Confirm",
            "delete_name": "Delete `{name}`",
            "are_you_sure_delete_customer": "Are you sure you want to delete the customer <strong>{name}</strong>?",
            "delete": "Delete",
            "add_customer": "Add customer",
            "active": "Active",
            "inactive": "Inactive",
            "no_customer_found": "No customer found",
            "add_customer_to_get_started": "Add a customer to get started.",
            "customers_inside": "Customers inside",
            "guests_inside": "Visitors inside",
            "parking_capacity": "Parking capacity",
            "entered_only": "Entered only",
            "exited": "Exited",
            "no_entries_found": "No entries found",
            "enter_vehicle_get_started": "Enter a vehicle to get started.",
            "last_48_hours": "Last 48 hours",
            "last_7_days": "Last 7 days",
            "last_30_days": "Last 30 days",
            "last_month": "Last month",
            "entrances": "Entrances",
            "exits": "Exits",
            "used": "Used",
            "month_1": "January",
            "month_1_short": "Jan",
            "month_2": "February",
            "month_2_short": "Feb",
            "month_3": "March",
            "month_3_short": "Mar",
            "month_4": "April",
            "month_4_short": "Apr",
            "month_5": "May",
            "month_5_short": "May",
            "month_6": "June",
            "month_6_short": "Jun",
            "month_7": "July",
            "month_7_short": "Jul",
            "month_8": "August",
            "month_8_short": "Aug",
            "month_9": "September",
            "month_9_short": "Sep",
            "month_10": "October",
            "month_10_short": "Oct",
            "month_11": "November",
            "month_11_short": "Nov",
            "month_12": "December",
            "month_12_short": "Dec",
            "day_1": "Sunday",
            "day_1_short": "Sun",
            "day_2": "Monday",
            "day_2_short": "Mon",
            "day_3": "Tuesday",
            "day_3_short": "Tue",
            "day_4": "Wednesday",
            "day_4_short": "Wed",
            "day_5": "Thursday",
            "day_5_short": "Thu",
            "day_6": "Friday",
            "day_6_short": "Fri",
            "day_7": "Saturday",
            "day_7_short": "Sat",
            "download_svg": "Download SVG",
            "download_png": "Download PNG",
            "download_csv": "Download CSV",
            "menu": "Menu",
            "selection": "Selection",
            "selection_zoom": "Selection Zoom",
            "zoom_in": "Zoom In",
            "zoom_out": "Zoom Out",
            "panning": "Panning",
            "reset_zoom": "Reset Zoom",
            "access_card": "Access card",
            "customer": "Customer",
            "user": "User",
            "enter_device": "Enter device",
            "exit_device": "Exit device",
            "enter_date_at": "Enter date at",
            "exit_date_at": "Exit date at",
            "total_duration": "Total duration",
            "no_event_found": "No event found",
            "Customer created": "Customer created",
            "Customer modified": "Customer modified",
            "Customer deleted": "Customer deleted",
            "User created": "User created",
            "User modified": "User modified",
            "User deleted": "User deleted",
            "Access card created": "Access card created",
            "Access card modified": "Access card modified",
            "Access card deleted": "Access card deleted",
            "User logged in": "User logged in",
            "Config created": "Config created",
            "Config modified": "Config modified",
            "Config deleted": "Config deleted",
            "Device connected": "Device connected",
            "Device disconnected": "Device disconnected",
            "has_logged_in": "has logged in",
            "has_created_the_customer_account": "has created the customer account",
            "has_modified_the_customer_account": "has modified the customer account",
            "has_deleted_the_customer_account": "has deleted the customer account",
            "has_created_the_access_card": "has created the access card",
            "has_modified_the_access_card": "has modified the access card",
            "has_deleted_the_access_card": "has deleted the access card",
            "has_created_the_user_account": "has created the user account",
            "has_modified_the_user_account": "has modified the user account",
            "has_deleted_the_user_account": "has deleted the user account",
            "has_connected": "has connected",
            "has_disconnected": "has disconnected",
            "has_modified_the_config": "has modified the config",
            "from": "from",
            "to": "to",
            "key": "Key",
            "no_guest_found": "No guest found",
            "add_guest_to_get_started": "Add a guest to get started.",
            "print": "Print",
            "add_access_card": "Add Access Card",
            "dynamic_qr": "Dynamic QR",
            "static_qr": "Static QR",
            "static_barcode": "Static Barcode",
            "are_you_sure_delete_access_card_name": "Are you sure you want to delete the access card <strong>{name}</strong>?",
            "max_entrance": "Max entrance",
            "between_hours_start": "Hours start",
            "between_hours_end": "Hours end",
            "signin": "Signin",
            "username": "Username",
            "password": "Password",
            "password_confirmation": "Password confirmation",
            "remember_username": "Remember username",
            "login": "Login",
            "invalid username or password provided": "Invalid username or password provided.",
            "feature_in_development": "This feature is in development",
            "feature_available_next_release": "This feature will be available in the next public release.",
            "parameter_name": "Parameter name",
            "value": "Value",
            "disabled": "Disabled",
            "enabled": "Enabled",
            "instantly": "Instantly",
            "5_minutes": "5 minutes",
            "10_minutes": "10 minutes",
            "15_minutes": "15 minutes",
            "30_minutes": "30 minutes",
            "45_minutes": "45 minutes",
            "60_minutes": "60 minutes",
            "notifications": "Notifications",
            "push_notifications": "Push notifications",
            "gate_blocked": "Gate blocked",
            "blocked": "Blocked",
            "device_disconnected": "Device disconnected",
            "device_reconnected": "Device reconnected",
            "enable_push_notifications_to_be_sent": "Enable push notifications to be sent to users who have subscribed to them.",
            "send_alert_when_gate_blocked_for_desired_time": "Send an alert when ever an object has been detected blocking the gate for more than desired time.",
            "send_alert_when_device_disconnect": "Send an alert when ever a device has been disconnected from the server for more than desired time.",
            "send_alert_when_device_reconnect": "Send an alert when ever a device has reconnected to the server after it send disconnection alert.",
            "add_user_account": "Edit user account",
            "edit_user_account": "Edit user account",
            "use_ldap": "Uses LDAP",
            "role": "Role",
            "operator": "Operator",
            "technician": "Technician",
            "save_user": "Save user account",
            "timezone": "Timezone",
            "user_modification_error": "User modification error",
            "password_confirmation_does_not_match": "Password confirmation does not match.",
            "no_access_card_found": "No access card found",
            "add_access_card_to_get_started": "Add an access card to get started.",
            "save_parameters": "Save parameters",
            "site_name": "Name of the site",
            "site_name_description": "Name to print on the coupons and show in communications.",
            "how_many_parking_spots": "How many parking spots in the parking.",
            "vehicles_inside": "Vehicles inside",
            "how_many_vehicles_inside": "How many vehicles are currently inside.",
            "maintenance_mode": "Maintenance mode",
            "enable_maintenance_mode_description": "Enable maintenance mode and allow every passages.",
            "server_hostname": "Server hostname",
            "ldap_server_hostname": "LDAP server ip address or hostname.",
            "server_port": "Server port",
            "ldap_server_port": "LDAP server port.",
            "ldap_secured": "LDAP secured",
            "ldap_secured_description": "Use LDAP secure protocol.",
            "example": "Example",
            "ldap_distinguished_names": "LDAP distinguished names",
            "smtp_server_ip_address": "SMTP server ip address or hostname.",
            "smtp_secured": "SMTP secured",
            "smtp_server_port": "SMTP server port",
            "use_smtp_secure_protocol": "Use SMTP secure protocol.",
            "smtp_account_username": "SMTP account username",
            "smtp_account_password": "SMTP account password",
            "smtp_send_from_name": "SMTP Send from (name)",
            "smtp_send_from_email": "SMTP Send from (email)",
            "this_name_will_be_sender": "This name will be displayed as the sender",
            "this_email_will_be_sender": "This email address will be displayed as the sender",
            "smtp_to_email_test": "SMTP To email (test only)",
            "send_test_mail_to_address": "Send a test mail to this address",
            "send_test_mail": "Send test mail",
            "smtp_settings_modification_success": "SMTP settings modification success",
            "success_smtp_settings_have_saved_successfully": "Success: The SMTP settings have been saved successfully.",
            "smtp_settings_modification_error": "SMTP settings modification error",
            "test_mail_error": "Test mail error",
            "please_fill_all_fields": "Please fill all the fields",
            "test_mail_success": "Test mail succeeded",
            "success_test_mail_sent_successfully": "Success: The test mail has been sent successfully.",
            "user_creation_error": "User creation error",
            "creation_date": "Creation date",
            "last_seen_date": "Last seen date",
            "are_you_sure_delete_user": "Are you sure you want to delete the customer <strong>{name}</strong>?",
            "add_user": "Add User",
            "no_user_found": "No user found",
            "max_vehicles_inside": "Max vehicles inside",
            "most_secured": "Most secure",
            "less_secured": "Less secure",
            "one_time_only": "One time only",
            "save_access_card": "Save access card",
            "access_card_creation_error": "Access card creation error",
            "edit_access_card": "Edit Access Card",
            "locale": "Language",
            "french": "French",
            "english": "English",
            "save_customer": "Save customer",
            "customer_modification_error": "Customer modification error",
            "edit_customer": "Edit Customer",
            "general_settings_modification_success": "General settings modification success",
            "general_settings_modification_error": "General settings modification error",
            "success_saved_general_settings": "Success: The General settings have been saved successfully.",
            "ldap_settings_modification_success": "LDAP settings modification success",
            "success_ldap_settings_saved_successfully": "Success: The LDAP settings have been saved successfully.",
            "ldap_settings_modification_error": "LDAP settings modification error",
            "controller": "Controller",
            "Entry created": "Entry created",
            "Failed to create entry": "Failed to create entry",
            "guest_entered_parking": "Guest has entered the parking",
            "average_per_day": "Average per day",
            "average_per_week": "Average per week",
            "average_per_month": "Average per month",
            "to_date": "To",
            "number_of_access": "Number of card(s) to create",
            "print_access_cards": "Print access cards",
            "do_you_want_to_print_access_cards": "Do you want to print {count} access card(s)",
            "week": "Week",
            "month": "Month",
            "year": "Year",
            "logo": "Logo",
            "logo_used": "Logo used for coupons.",
            "logo_used2": "Logo used for emails.",
            "allowed_days": "Allowed days",
            "max_vehicles": "Maximum vehicles",
            "nip": "NIP",
            "coupon_text_fr": "Coupon text french",
            "coupon_text_en": "Coupon text english",
            "coupon_text_description": "Text to show at the bottom of a coupon.",
            "unknown": "Unknown",
            "keep_open": "Keep the gate open",
            "indefinitely": "Indefinitely",
            "email_text_fr2": "Email intro text french",
            "email_text_en2": "Email intro text english",
            "email_text_description": "Text to display below the access card.",
            "email_text_description2": "Text to show above the access card.",
            "email_text_fr": "Email outro text french",
            "email_text_en": "Email outro text english",
            "hid_card": "HID Card",
            "card_key": "Key of the card",
            "auto_exit_entries": "Auto exit entries",
            "auto_exit_entries_description": "Entries will be exited automatically at midnight.",
            "today_activities": "Today activities",
            "has_sent_stay_open_signal": "has manually sent stay open signal to the device",
            "has_sent_open_signal": "has manually sent open signal to the device",
            "has_sent_close_signal": "has manually sent close signal to the device",
            "for_a_duration_of": "for a duration of",
            "_indefinitely_": "`Indefinitely`",
            "received_gate_signal": "received gate signal",
            "state_changed_to": "State has changed to",
            "User sent signal": "User sent signal",
            "User": "User",
            "date": "Date",
            "disable_max_vehicles": "Disable vehicle limit",
            "disable_max_vehicles_description": "Disable maximum vehicle limit",
            "language": "Language",
            "yes": "yes",
            "no": "no",
            "Device state changed": "Device state changed",
            "max vehicules inside limit reached": "Maximum limit of vehicles inside reached.",
            "has_created_zone": "has created the zone",
            "has_modified_zone": "has modified the zone",
            "has_deleted_zone": "has deleted the zone",
            "Zone created": "Zone created",
            "Zone modified": "Zone modified",
            "Zone deleted": "Zone deleted",
            "zones": "Zones",
            "are_you_sure_delete_zone": "Are you sure you want to delete the zone <strong>{name}</strong>?",
            "save_zone": "Save zone",
            "edit_zone": "Edit zone",
            "add_zone": "Add a zone",
            "no_zone_found": "No zone found",
            "pricings": "Pricings",
            "pricing": "Pricing",
            "no_pricings_found": "No pricing found",
            "add_pricing": "Add pricing",
            "normal": "Normal",
            "special": "Special",
            "holidays": "Holidays",
            "days": "Days",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "sunday": "Sunday",
            "pricing_hour_min": "Effective start time",
            "pricing_hour_max": "Effective end time",
            "pricing_date_min": "Effective start date",
            "pricing_date_max": "Effective end date",
            "priority": "Priority",
            "hourly_rate": "Hourly rate",
            "daily_max_rate": "Daily max rate",
            "weekly_max_rate": "Weekly max rate",
            "monthly_max_rate": "Monthly max rate",
            "off_peak_hourly_rate": "Off-peak hours rate",
            "off_peak_hour_morning_start": "Morning off-peak start times",
            "off_peak_hour_morning_end": "Morning off-peak end times",
            "off_peak_hour_evening_start": "Evening off-peak start times",
            "off_peak_hour_evening_end": "Evening off-peak end times",
            "adjust_daylight_timezone": "Adjust for daylight saving time automatically",
            "AutomaticSystems Barrier": "AutomaticSystems Barrier",
            "ELKA Barrier": "ELKA Barrier",
            "PAX Terminal": "PAX Terminal",
            "save_pricing": "Save pricing",
            "average": "Average",

            "tax_name": "Tax name",
            "how_much_percent_to_apply": "Percentage of tax to apply",
            "enable_use_taxes_description": "Enable this option to apply a tax on pricing",
            "use_taxes": "Use taxes",
            "in order to enter parking": "to enter the zone",
            "transactions": "Transactions",
            "tax_percent": "Tax percentage",
            "tax_name_description": "Tax name to print",
            "daylight_offset": "Daylight saving time offset",
            "Gate has been opened by": "The device has been opened by",
            "Gate has been opened in order to enter zone.": "Gate has been opened in order to enter zone.",
            "Gate has been opened in order to exit zone.": "Gate has been opened in order to exit zone.",
            "Customer entered": "Customer entered",
            "Staff entered": "User entered",
            "Error: Network Error": "Error: Network error",
            "customer_passages": "Customer passes",
            "users_passages": "User passes",
            "guests_passages": "Guests passes",
            "add_device": "Add device",
            "save_device": "Save device",
            "Type": "Type",
            "Barrier": "Portal",
            "total_price": "Total price",
            "entry_id": "# Entry",
            "identifier": "Identifier",
            "masked_pan": "Masked PAN",
            "card_brand": "Provider",
            "amount": "Amount",
            "refunded": "Refunded",
            "purchase": "Purchase",
            "Customer exited": "Customer exited",

            "relay_operating_mode": "Relay operation mode",
            "deactivated": "Disabled",
            "maintenance": "Maintenance",
            "boom_missing": "Missing rail",
            "tandem_operation": "Tandem operation",
            "red_traffic_light": "Red light",
            "green_traffic_light": "Green light",
            "warning_light": "Yellow light",
            "led_boom_lighting": "LED boom lighting",
            "activated_when_barrier_open": "Activated when the barrier is open",
            "activated_when_barrier_closed": "Activated when the barrier is closed",
            "activated_via_bus": "Activated via bus",
            "photoelectric_barrier_testing": "Photoelectric barrier test",
            "barrier_emergency_released": "Emergency barrier release",
            "activated_when_loop_a_occupied": "Activated when loop A is occupied",
            "activated_when_loop_a_activated_notfaulty_notoccupied": "Activated when loop A is activated not faulty not occupied",
            "pulse_when_loop_a_occupied": "Pulse when loop A is occupied",
            "pulse_when_loop_a_cleared": "Pulse when loop A is released",
            "activated_when_loop_b_occupied": "Activated when loop B is occupied",
            "activated_when_loop_b_activated_notfaulty_notoccupied": "Activated when loop B is activated not faulty not occupied",
            "pulse_when_loop_b_occupied": "Pulse when loop B is occupied",
            "pulse_when_loop_b_cleared": "Pulse when loop B is released",
            "activated_when_loop_c_occupied": "Activated when loop C is occupied",
            "activated_when_loop_c_activated_notfaulty_notoccupied": "Activated when loop C is activated not faulty not occupied",
            "pulse_when_loop_c_occupieday": "Pulse when loop C is occupied",
            "pulse_when_loop_c_cleared": "Pulse when loop C is released",
            "pulse_when_vehicle_counter_increment": "Pulse when vehicle counter increments",
            "pulse_when_vehicle_counter_decrement": "Pulse when vehicle counter decrements",
            "pulse_when_dl_detected_passing_left": "Pulse when dl detects a passage to the left",
            "pulse_when_dl_detected_passing_right": "Pulse when dl detects a passage to the right",
            "on_when_barrier_closed": "On when the barrier is closed",
            "on_when_tcp_connected": "On when TCP is connected",
            "on_when_powerfailuredetection_connected_andnofailure": "On when power failure detection is connected and no failure",
            "activated_when_acousticsignaldevice_ison": "Activated when the sound signal device is turned on",
            "ledboom_controlling_green_leds": "LED boom controlling green LEDs",
            "ledboom_controlling_red_leds": "LED boom controlling red LEDs",
            "allow_exit_no_entry": "Allow exit without prior existing entry",
            "allow_exit_no_entry_description": "This option is required for parking lots that have one or more exits in free mode without validation.",
            "Validation failed": "Validation failed",
            "a customer": "a customer",
            "a guest": "a guest",
            "a user": "a user",

            "Printer status changed": "Printer status changed",
            "The printer device has connected.": "The printer device has connected.",
            "The printer device has been disconnected.": "The printer device has been disconnected.",
            "The printer device is online.": "The printer device is online.",
            "The printer device is offline.": "The printer device is offline.",
            "The printer device is waiting for online error recovery.": "The printer device is waiting for online error recovery.",
            "The printer device has recovered.": "The printer device has recovered.",
            "The printer device feed button has been pressed.": "The printer device feed button has been pressed.",
            "The printer device feed button has been released.": "The printer device feed button has been released.",
            "The printer plate has been opened.": "The printer plate has been opened.",
            "The printer plate has been closed.": "The printer plate has been closed.",
            "The printer device is feeding by button.": "The printer device is feeding by button.",
            "The printer device is no longer feeding by button.": "The printer device is no longer feeding by button.",
            "The printer device sensor for paper end stop is active.": "The printer device sensor for paper end stop is active.",
            "The printer device sensor for paper end stop is inactive.": "The printer device sensor for paper end stop is inactive.",
            "The printer device has occurred an error.": "The printer device has occurred an error.",
            "The printer device has recovered from an error.": "The printer device has recovered from an error.",
            "The printer device has occurred a mechanical error.": "The printer device has occurred a mechanical error.",
            "The printer device has recovered from a mechanical error.": "The printer device has recovered from a mechanical error.",
            "The printer device has occurred an auto cutter error.": "The printer device has occurred an auto cutter error.",
            "The printer device has recovered from an auto cutter error.": "The printer device has recovered from an auto cutter error.",
            "The printer device has occurred an unrecoverable error.": "The printer device has occurred an unrecoverable error.",
            "The printer device has recovered from an unrecoverable error.": "The printer device has recovered from an unrecoverable error.",
            "The printer device has occurred an auto recoverable error.": "The printer device has occurred an auto recoverable error.",
            "The printer device has recovered from an auto recoverable error.": "The printer device has recovered from an auto recoverable error.",
            "The printer device has detected adequate paper.": "The printer device has detected adequate paper.",
            "The printer device has detected inadequate paper.": "The printer device has detected inadequate paper.",
            "The printer device has detected paper near end.": "The printer device has detected paper near end.",
            "The printer device has undetected paper near end.": "The printer device has undetected paper near end.",
            "The printer device has detected paper.": "The printer device has detected paper.",
            "The printer device has detected missing paper.": "The printer device has detected missing paper.",
            "The printer device has detected paper roll end.": "The printer device has detected paper roll end.",
            "The printer device has undetected paper roll end.": "The printer device has undetected paper roll end.",
            "event": "Event",
            "Card scanned": "Access card scanned",
            "The card with number": "The access card with number",
            "has been scanned": "has been scanned",
            "Coupon scanned": "QR code scanned",
            "The qr coupon with number": "The QR code with number",
            "PIN submited": "PIN submitted",
            "The PIN with number": "The PIN code with number",
            "has been submited": "has been submitted",
            "access card is not allowed to do entries": "Customers access card are not allowed to do entries"
        },
        "fr": {
            "Guest entered": "Invité entré",
            "Loading": "Chargement",
            "car": "voiture",
            "boat": "bateau",
            "bicycle": "vélo",
            "person": "personne",
            "bus": "bus",
            "motorcycle": "motocyclette",
            "live": "En direct",
            "grace_period": "Délai de grâce",
            "grace_period_description": "Le délai de grâce permet aux visiteurs de sortir sans avoir à payer s'ils respectent le délai. (En minutes)",
            "max_usages": "Utilisations maximales",
            "reboot": "Redémarrer",
            "reboot_device": "Redémarrer l'appareil",
            "are_you_sure_reboot_device": "Êtes-vous sûr de vouloir redémarrer l'appareil <strong>{name}</strong>?",
            "MaxEntrancesReached": "Nombre maximal d'entrées atteint",
            "has already reached max entrances": "a déjà atteint le nombre maximum d'entrées",
            "DoesNotExists": "N'existe pas",
            "unable to find access card": "Impossible de trouver la carte d'accès",
            "has_subscription": "A un abonnement",
            "today": "Aujourd'hui",
            "license": "License",
            "expiration": "Expiration",
            "system_uptime": "Disponibilité",
            "total_entrances": "Entrées totales",
            "total_exits": "Sorties totales",
            "total_customers": "Clients totaux",
            "total_users": "Utilisateurs totaux",
            "total_guests": "Visiteurs totaux",
            "capacity": "Capacité",
            "view_all": "Voir tous",
            "view": "Voir",
            "inside": "À l'intérieur",
            "user_entrances": "Entrées utilisateurs",
            "user_exits": "Sorties utilisateurs",
            "customer_entrances": "Entrées clients",
            "customer_exits": "Sorties clients",
            "guest_entrances": "Entrées visiteurs",
            "guest_exits": "Sorties visiteurs",
            "traffic_overview": "Aperçu du trafic",
            "most_active_customers": "Clients les plus actifs",
            "devices_overview": "Aperçu des appareils",
            "most_active_users": "Utilisateurs les plus actifs",
            "statistics_overview": "Aperçu des statistiques",
            "Tag(s)": "Étiquette(s)",
            "tags": "Étiquettes",
            "tag": "Étiquette",
            "no_tag_found": "Aucune étiquette trouvé",
            "add_tag": "Ajouter une étiquette",
            "edit_tag": "Modifier une étiquette",
            "save_tag": "Sauvegarder l'étiquette",
            "are_you_sure_delete_tag": "Êtes-vous sûr de vouloir supprimer cette étiquette?",
            "settings": "Paramètres",
            "general": "Général",
            "users": "Utilisateurs",
            "guest": "Visiteur",
            "alerts": "Alertes",
            "mails": "Courriels",
            "signout": "Déconnexion",
            "connected": "Connecté",
            "disconnected": "Déconnecté",
            "open": "Ouvert",
            "opening": "Ouverture",
            "closed": "Fermé",
            "closing": "Fermeture",
            "opened": "Ouvert",
            "modify": "Modifier",
            "all": "Tous",
            "no_device_found": "Aucun appareil trouvé",
            "Entrance gate": "Portail d'entrée",
            "Exit gate": "Portail de sortie",
            "try_connecting_device": "Essayez de connecter un appareil sur le même réseau ou ajoutez-le manuellement.",
            "name": "Nom",
            "brand": "Marque",
            "model": "Modèle",
            "type": "Type",
            "ip_addr": "IP Addr",
            "mac_addr": "MAC Addr",
            "status": "Statut",
            "state": "État",
            "devices": "Appareils",
            "device": "Appareil",
            "dashboard": "Tableau de bord",
            "events": "Événements",
            "customers": "Clients",
            "guests": "Visiteurs",
            "entries": "Entrées",
            "statistics": "Statistiques",
            "open_gate": "Ouvrir le portail",
            "close_gate": "Fermer le portail",
            "SELECT": {
                "ok": "OK",
                "cancel": "ANNULER"
            },
            "cancel": "Annuler",
            "none": "Aucun",
            "overview": "Aperçu",
            "save_config": "Enregistrer la configuration",
            "configuration": "Configuration",
            "signals": "Signaux",
            "save_signals": "Enregistrer les signaux",
            "output_signal": "Signal de sortie",
            "input_signal": "Signal d'entrée",
            "keep_gate_open": "Gardez le portail ouvert",
            "gate_opened": "Le portail s'est ouvert",
            "gate_closed": "Le portail s'est fermé",
            "security_loop_triggered": "La boucle de sécurité est déclenchée",
            "presence_loop_triggered": "Un véhicule est détecté",
            "presence": "présence",
            "search_here": "Rechercher ici...",
            "output": "Sortie",
            "input": "Entrée",
            "relay": "Relais",
            "device_modified": "Appareil modifié",
            "saved_configuration_successfully": "Configuration enregistrée avec succès.",
            "device_modification_error": "Erreur de modification de l'appareil",
            "error": "Erreur",
            "saved_signals_successfully": "Signaux enregistrés avec succès.",
            "gate_opening": "Ouverture du portail",
            "gate_closing": "Fermeture du portail",
            "gate_successfully_opening": "Le portail s'ouvre avec succès.",
            "gate_successfully_closing": "Le portail se ferme avec succès.",
            "gate_error": "Erreur du portail",
            "cannot_open_gate": "Impossible d'ouvrir le portail actuellement.",
            "cannot_close_gate": "Impossible de fermer le portail actuellement",
            "description": "Description",
            "date_at": "Date à",
            "critical": "Critique",
            "important": "Important",
            "information": "Information",
            "no_alert_found": "Aucune alerte trouvée",
            "try_connecting_device_or_configure_system": "Essayez de connecter un appareil ou de configurer le système.",
            "email": "Courriel",
            "validation_date": "Date de validation",
            "expiration_date": "Date d'expiration",
            "confirm": "Confirmer",
            "delete_name": "Supprimer `{name}`",
            "are_you_sure_delete_customer": "Êtes-vous sûr de vouloir supprimer le client <strong>{name}</strong>?",
            "delete": "Supprimer",
            "add_customer": "Ajouter un client",
            "active": "Actif",
            "inactive": "Inactif",
            "no_customer_found": "Aucun client trouvé",
            "add_customer_to_get_started": "Ajoutez un client pour commencer.",
            "customers_inside": "Clients à l'intérieur",
            "guests_inside": "Visiteurs à l'intérieur",
            "parking_capacity": "Capacité du stationnement",
            "entered_only": "Entré seulement",
            "exited": "Sorti",
            "no_entries_found": "Aucune entrée trouvée",
            "enter_vehicle_get_started": "Entrez un véhicule pour commencer.",
            "last_48_hours": "Dernières 48 heures",
            "last_7_days": "Les 7 derniers jours",
            "last_30_days": "Les 30 derniers jours",
            "last_month": "Derniers mois",
            "entrances": "Entrées",
            "exits": "Sorties",
            "used": "Utilisé",
            "month_1": "Janvier",
            "month_1_short": "Jan",
            "month_2": "Février",
            "month_2_short": "Fév",
            "month_3": "Mars",
            "month_3_short": "Mar",
            "month_4": "Avril",
            "month_4_short": "Avr",
            "month_5": "Mai",
            "month_5_short": "Mai",
            "month_6": "Juin",
            "month_6_short": "Juin",
            "month_7": "Juillet",
            "month_7_short": "Jui",
            "month_8": "Août",
            "month_8_short": "Aoû",
            "month_9": "Septembre",
            "month_9_short": "Sep",
            "month_10": "Octobre",
            "month_10_short": "Oct",
            "month_11": "Novembre",
            "month_11_short": "Nov",
            "month_12": "Décembre",
            "month_12_short": "Déc",
            "day_1": "Dimanche",
            "day_1_short": "Dim",
            "day_2": "Lundi",
            "day_2_short": "Lun",
            "day_3": "Mardi",
            "day_3_short": "Mar",
            "day_4": "Mercredi",
            "day_4_short": "Mer",
            "day_5": "Jeudi",
            "day_5_short": "Jeu",
            "day_6": "Vendredi",
            "day_6_short": "Ven",
            "day_7": "Samedi",
            "day_7_short": "Sam",
            "download_svg": "Télécharger au format SVG",
            "download_png": "Télécharger au format PNG",
            "download_csv": "Télécharger au format CSV",
            "menu": "Menu",
            "selection": "Sélection",
            "selection_zoom": "Zoom de sélection",
            "zoom_in": "Agrandir",
            "zoom_out": "Rétrécir",
            "panning": "Défiler",
            "reset_zoom": "Réinitialiser le zoom",
            "access_card": "Carte d'accès",
            "customer": "Client",
            "user": "Utilisateur",
            "enter_device": "Appareil d'entrée",
            "exit_device": "Appareil de sortie",
            "enter_date_at": "Date d'entrée",
            "exit_date_at": "Date de sortie",
            "total_duration": "Durée totale",
            "no_event_found": "Aucun événement trouvé",
            "Customer created": "Client créé",
            "Customer modified": "Client modifié",
            "Customer deleted": "Client supprimé",
            "User created": "Utilisateur créé",
            "User modified": "Utilisateur modifié",
            "User deleted": "Utilisateur supprimé",
            "Access card created": "Carte d'accès créée",
            "Access card modified": "Carte d'accès modifiée",
            "Access card deleted": "Carte d'accès supprimée",
            "User logged in": "Utilisateur connecté",
            "Config created": "Paramètre créée",
            "Config modified": "Paramètre modifié",
            "Config deleted": "Paramètre supprimée",
            "Device connected": "Appareil connecté",
            "Device disconnected": "Appareil déconnecté",
            "has_logged_in": "s'est connecté",
            "has_created_the_customer_account": "a créé le compte client",
            "has_modified_the_customer_account": "a modifié le compte client",
            "has_deleted_the_customer_account": "a supprimé le compte client",
            "has_created_the_access_card": "a créé la carte d'accès",
            "has_modified_the_access_card": "a modifié la carte d'accès",
            "has_deleted_the_access_card": "a supprimé la carte d'accès",
            "has_created_the_user_account": "a créé le compte utilisateur",
            "has_modified_the_user_account": "a modifié le compte utilisateur",
            "has_deleted_the_user_account": "a supprimé le compte utilisateur",
            "has_connected": "s'est connecté",
            "has_disconnected": "s'est déconnecté",
            "has_modified_the_config": "a modifié le paramètre",
            "from": "Du",
            "to": "pour",
            "key": "Clé",
            "no_guest_found": "Aucun invité trouvé",
            "add_guest_to_get_started": "Ajoutez un invité pour commencer.",
            "print": "Imprimer",
            "add_access_card": "Ajouter une carte d'accès",
            "dynamic_qr": "QR dynamique",
            "static_qr": "QR statique",
            "static_barcode": "Code-barres statique",
            "are_you_sure_delete_access_card_name": "Voulez-vous vraiment supprimer la carte d'accès <strong>{name}</strong>?",
            "max_entrance": "Entrée maximale",
            "between_hours_start": "Heures de début",
            "between_hours_end": "Heures de fin",
            "signin": "S'identifier",
            "username": "Nom d'utilisateur",
            "password": "Mot de passe",
            "password_confirmation": "Confirmation du mot de passe",
            "remember_username": "Se souvenir du nom d'utilisateur",
            "login": "Connexion",
            "invalid username or password provided": "Nom d'utilisateur ou mot de passe fourni invalide.",
            "feature_in_development": "Cette fonctionnalité est en développement",
            "feature_available_next_release": "Cette fonctionnalité sera disponible dans la prochaine version publique.",
            "parameter_name": "Nom du paramètre",
            "value": "Valeur",
            "disabled": "Désactivé",
            "enabled": "Activé",
            "instantly": "Immédiatement",
            "5_minutes": "5 minutes",
            "10_minutes": "10 minutes",
            "15_minutes": "15 minutes",
            "30_minutes": "30 minutes",
            "45_minutes": "45 minutes",
            "60_minutes": "60 minutes",
            "notifications": "Notifications",
            "push_notifications": "Notifications push",
            "gate_blocked": "Portail bloquée",
            "blocked": "Bloquée",
            "device_disconnected": "Appareil déconnecté",
            "device_reconnected": "Appareil reconnecté",
            "enable_push_notifications_to_be_sent": "Activer les notifications push à envoyer aux utilisateurs qui y sont abonnés.",
            "send_alert_when_gate_blocked_for_desired_time": "Envoyez une alerte chaque fois qu'un objet a été détecté bloquant la porte pendant plus de temps que souhaité.",
            "send_alert_when_device_disconnect": "Envoyez une alerte chaque fois qu'un appareil a été déconnecté du serveur pendant plus de temps que souhaité.",
            "send_alert_when_device_reconnect": "Envoyez une alerte chaque fois qu'un appareil s'est reconnecté au serveur après avoir envoyé une alerte de déconnexion.",
            "add_user_account": "Ajouter un compte utilisateur",
            "edit_user_account": "Modifier le compte utilisateur",
            "use_ldap": "Utilise LDAP",
            "role": "Rôle",
            "operator": "Opérateur",
            "technician": "Technicien",
            "save_user": "Enregistrer le compte utilisateur",
            "timezone": "Fuseau horaire",
            "user_modification_error": "Erreur de modification de l'utilisateur",
            "password_confirmation_does_not_match": "La confirmation du mot de passe ne correspond pas.",
            "no_access_card_found": "Aucune carte d'accès trouvée",
            "add_access_card_to_get_started": "Ajoutez une carte d'accès pour commencer.",
            "save_parameters": "Enregistrer les paramètres",
            "site_name": "Nom du site",
            "site_name_description": "Nom à imprimer sur les coupons et à afficher dans les communications.",
            "how_many_parking_spots": "Combien de places de stationnement dans le parking.",
            "vehicles_inside": "Véhicules à l'intérieur",
            "how_many_vehicles_inside": "Combien de véhicules sont actuellement à l'intérieur.",
            "maintenance_mode": "Mode de Maintenance",
            "enable_maintenance_mode_description": "Activer le mode maintenance et autoriser tous les passages.",
            "server_hostname": "Nom d'hôte du serveur",
            "ldap_server_hostname": "Adresse IP ou nom d'hôte du serveur LDAP.",
            "server_port": "Port du serveur",
            "ldap_server_port": "Port du serveur LDAP.",
            "ldap_secured": "LDAP sécurisé",
            "ldap_secured_description": "Utilisez le protocole sécurisé LDAP.",
            "example": "Exemple",
            "ldap_distinguished_names": "LDAP noms distingués",
            "smtp_server_ip_address": "Adresse IP ou nom d'hôte du serveur SMTP.",
            "smtp_secured": "SMTP sécurisé",
            "smtp_server_port": "SMTP port de serveur",
            "use_smtp_secure_protocol": "Utiliser le protocole sécurisé SMTP.",
            "smtp_account_username": "Nom d'utilisateur du compte SMTP",
            "smtp_account_password": "Mot de passe du compte SMTP",
            "smtp_send_from_name": "Envoi SMTP depuis (nom)",
            "smtp_send_from_email": "Envoi SMTP depuis (e-mail)",
            "this_name_will_be_sender": "Ce nom sera affiché comme expéditeur",
            "this_email_will_be_sender": "Cette adresse e-mail sera affichée comme expéditeur",
            "smtp_to_email_test": "SMTP vers e-mail (test uniquement)",
            "send_test_mail_to_address": "Envoyez un mail test à cette adresse",
            "send_test_mail": "Envoyer un e-mail test",
            "smtp_settings_modification_success": "Succès de la modification des paramètres SMTP",
            "success_smtp_settings_have_saved_successfully": "Succès: les paramètres SMTP ont été enregistrés avec succès.",
            "smtp_settings_modification_error": "Erreur de modification des paramètres SMTP",
            "test_mail_error": "Erreur de test de messagerie",
            "please_fill_all_fields": "Veuillez remplir tous les champs",
            "test_mail_success": "Le test de messagerie a réussi",
            "success_test_mail_sent_successfully": "Réussite: L'e-mail de test a été envoyé avec succès.",
            "user_creation_error": "Erreur de création d'utilisateur",
            "creation_date": "Date de création",
            "last_seen_date": "Date de la dernière visite",
            "are_you_sure_delete_user": "Voulez-vous vraiment supprimer le client <strong>{name}</strong>?",
            "add_user": "Ajouter un utilisateur",
            "no_user_found": "Aucun utilisateur trouvé",
            "max_vehicles_inside": "Max véhicules à l'intérieur",
            "most_secured": "Plus sécurisé",
            "less_secured": "Moins sécurisé",
            "one_time_only": "Une fois seulement",
            "save_access_card": "Enregistrer la carte d'accès",
            "access_card_creation_error": "Erreur de création de carte d'accès",
            "edit_access_card": "Modifier la carte d'accès",
            "locale": "Langue",
            "french": "Francais",
            "english": "Anglais",
            "save_customer": "Enregistrer le client",
            "customer_modification_error": "Erreur de modification client",
            "edit_customer": "Modifier le client",
            "general_settings_modification_success": "Succès de la modification des paramètres généraux",
            "general_settings_modification_error": "Erreur de modification des paramètres généraux",
            "success_saved_general_settings": "Succès: les paramètres généraux ont été enregistrés avec succès.",
            "ldap_settings_modification_success": "Succès de la modification des paramètres LDAP",
            "success_ldap_settings_saved_successfully": "Succès: les paramètres LDAP ont été enregistrés avec succès.",
            "ldap_settings_modification_error": "Erreur de modification des paramètres LDAP",
            "controller": "Contrôleur",
            "Entry created": "Entrée créée",
            "Failed to create entry": "Échec de la création de l'entrée",
            "guest_entered_parking": "Un invité est entré dans le parking",
            "average_per_day": "Moyenne par jour",
            "average_per_week": "Moyenne par semaine",
            "average_per_month": "Moyenne par mois",
            "to_date": "Au",
            "number_of_access": "Nombre de carte(s) à créer",
            "print_access_cards": "Imprimer des cartes d'accès",
            "do_you_want_to_print_access_cards": "Voulez-vous imprimer {count} carte(s) d'accès",
            "week": "Semaine",
            "month": "Mois",
            "year": "Année",
            "logo": "Logo",
            "logo_used": "Logo utilisé pour les coupons.",
            "logo_used2": "Logo utilisé pour les courriels.",
            "allowed_days": "Jours autorisés",
            "max_vehicles": "Véhicules maximum",
            "nip": "PIN",
            "coupon_text_fr": "Texte du coupon en français",
            "coupon_text_en": "Texte du coupon en anglais",
            "coupon_text_description": "Texte à afficher au bas d'un coupon.",
            "unknown": "Inconnue",
            "keep_open": "Garder le portail ouvert",
            "indefinitely": "Indéfiniment",
            "email_text_fr2": "Texte d'introduction du courriel en français",
            "email_text_en2": "Texte d'introduction du courriel en anglais",
            "email_text_description": "Texte à afficher en-dessous de la carte d'accès.",
            "email_text_description2": "Texte à afficher au-dessus de la carte d'accès.",
            "email_text_fr": "Text d'outro du courriel en français",
            "email_text_en": "Text d'outro du courriel en anglais",
            "hid_card": "Carte HID",
            "card_key": "Clé de la carte",
            "auto_exit_entries": "Fermées automatiquement les entrées",
            "auto_exit_entries_description": "Les entrées seront fermées automatiquement à minuit.",
            "today_activities": "Activités d'aujourd'hui",
            "has_sent_stay_open_signal": "a envoyé manuellement un signal de garder ouvert à l'appareil",
            "has_sent_open_signal": "a envoyé manuellement un signal d'ouverture à l'appareil",
            "has_sent_close_signal": "a envoyé manuellement un signal de fermeture à l'appareil",
            "for_a_duration_of": "pour une durée de",
            "_indefinitely_": "`Indéfiniment`",
            "received_gate_signal": "signal du portail reçu",
            "state_changed_to": "L'état est passé à",
            "User sent signal": "Signal envoyé par l'utilisateur",
            "User": "Utilisateur",
            "date": "Date",
            "disable_max_vehicles": "Désactiver la limite de véhicule",
            "disable_max_vehicles_description": "Désactiver la limite maximale de véhicules",
            "language": "Langue",
            "yes": "oui",
            "no": "non",
            "Device state changed": "État de l'appareil changé",
            "max vehicules inside limit reached": "Limite max de véhicules à l'intérieur atteinte.",
            "has_created_zone": "a créé la zone",
            "has_modified_zone": "a modifié la zone",
            "has_deleted_zone": "a supprimé la zone",
            "Zone created": "Zone créée",
            "Zone modified": "Zone modifiée",
            "Zone deleted": "Zone supprimée",
            "zones": "Zones",
            "are_you_sure_delete_zone": "Voulez-vous vraiment supprimer la zone <strong>{name}</strong>?",
            "save_zone": "Sauvegarder la zone",
            "edit_zone": "Modifier la zone",
            "add_zone": "Ajouter une zone",
            "no_zone_found": "Aucune zone trouvée",
            "pricings": "Tarifications",
            "pricing": "Tarification",
            "no_pricings_found": "Aucune tarifications trouvé",
            "add_pricing": "Ajouter un tarif",
            "normal": "Normal",
            "special": "Spécial",
            "holidays": "Jour férié",
            "days": "Jours",
            "monday": "Lundi",
            "tuesday": "Mardi",
            "wednesday": "Mercredi",
            "thursday": "Jeudi",
            "friday": "Vendredi",
            "saturday": "Samedi",
            "sunday": "Dimanche",
            "pricing_hour_min": "Heure début effectif",
            "pricing_hour_max": "Heure fin effectif",
            "pricing_date_min": "Date début effectif",
            "pricing_date_max": "Date fin effectif",
            "priority": "Priorité",
            "hourly_rate": "Taux horaire",
            "daily_max_rate": "Tarif journalier maximum",
            "weekly_max_rate": "Tarif hebdomadaire maximum",
            "monthly_max_rate": "Tarif mensuel maximum",
            "off_peak_hourly_rate": "Tarif hors des heures de pointes",
            "off_peak_hour_morning_start": "Début hors pointes du matin",
            "off_peak_hour_morning_end": "Fin hors pointes du matin",
            "off_peak_hour_evening_start": "Début hors pointes du soir",
            "off_peak_hour_evening_end": "Fin hors pointes du soir",
            "adjust_daylight_timezone": "Ajuster automatiquement à l'heure d'été",
            "AutomaticSystems Barrier": "Barrière AutomaticSystems",
            "ELKA Barrier": "Barrière ELKA",
            "PAX Terminal": "Terminal PAX",
            "save_pricing": "Sauvegarder le tarif",
            "average": "Moyenne",
            "tax_name": "Nom de la taxe",
            "how_much_percent_to_apply": "Pourcentage de taxe à appliquer",
            "enable_use_taxes_description": "Activer cette option pour appliquer une taxe sur la tarification",
            "use_taxes": "Utiliser les taxes",
            "in order to enter parking": "pour entrer dans la zone",
            "transactions": "Transactions",
            "tax_percent": "Pourcentage de la taxe",
            "tax_name_description": "Nom de la taxe à imprimer",
            "daylight_offset": "Décalage de l'heure d'été",
            "Gate has been opened by": "L'appareil à été ouvert par",
            "Gate has been opened in order to enter zone.": "L'appareil à été ouvert pour entré dans la zone.",
            "Gate has been opened in order to exit zone.": "L'appareil à été ouvert pour sortir de la zone.",
            "Customer entered": "Client entré",
            "Staff entered": "Utilisateur entré",
            "Error: Network Error": "Erreur: Erreur réseau",
            "customer_passages": "Passages client",
            "users_passages": "Passages utilisateur",
            "guests_passages": "Passages visiteur",
            "add_device": "Ajouter un appareil",
            "save_device": "Sauvegarder l'appareil",
            "Type": "Type",
            "Barrier": "Portail",
            "total_price": "Prix total",
            "entry_id": "# Entrée",
            "identifier": "Identifiant",
            "masked_pan": "PAN masquée",
            "card_brand": "Fournisseur",
            "amount": "Montant",
            "refunded": "Remboursé",
            "purchase": "Achat",
            "Customer exited": "Client sorti",

            "relay_operating_mode": "Mode de fonctionnement du relais",
            "deactivated": "Désactivé",
            "maintenance": "Maintenance",
            "boom_missing": "Lisse manquante",
            "tandem_operation": "Fonctionnement en tandem",
            "red_traffic_light": "Feu rouge",
            "green_traffic_light": "Feu vert",
            "warning_light": "Feu jaune",
            "led_boom_lighting": "Éclairage de bras à LED",
            "activated_when_barrier_open": "Activé lorsque la barrière est ouverte",
            "activated_when_barrier_closed": "Activé lorsque la barrière est fermée",
            "activated_via_bus": "Activé via le bus",
            "photoelectric_barrier_testing": "Test de barrière photoélectrique",
            "barrier_emergency_released": "Déverrouillage d'urgence de la barrière",
            "activated_when_loop_a_occupied": "Activé lorsque la boucle A est occupée",
            "activated_when_loop_a_activated_notfaulty_notoccupied": "Activé lorsque la boucle A est activée non défaillante non occupée",
            "pulse_when_loop_a_occupied": "Impulsion lorsque la boucle A est occupée",
            "pulse_when_loop_a_cleared": "Impulsion lorsque la boucle A est libérée",
            "activated_when_loop_b_occupied": "Activé lorsque la boucle B est occupée",
            "activated_when_loop_b_activated_notfaulty_notoccupied": "Activé lorsque la boucle B est activée non défaillante non occupée",
            "pulse_when_loop_b_occupied": "Impulsion lorsque la boucle A est occupée",
            "pulse_when_loop_b_cleared": "Impulsion lorsque la boucle B est libérée",
            "activated_when_loop_c_occupied": "Activé lorsque la boucle C est occupée",
            "activated_when_loop_c_activated_notfaulty_notoccupied": "Activé lorsque la boucle C est activée non défaillante non occupée",
            "pulse_when_loop_c_occupieday": "Impulsion lorsque la boucle C est occupée",
            "pulse_when_loop_c_cleared": "Impulsion lorsque la boucle C est libérée",
            "pulse_when_vehicle_counter_increment": "Impulsion lorsque le compteur du véhicule incrémente",
            "pulse_when_vehicle_counter_decrement": "Impulsion lorsque le compteur du véhicule décrémente",
            "pulse_when_dl_detected_passing_left": "Impulsion lorsque dl détecte un passage à gauche",
            "pulse_when_dl_detected_passing_right": "Impulsion lorsque dl détecte un passage à droite",
            "on_when_barrier_closed": "Allumé lorsque la barrière est fermée",
            "on_when_tcp_connected": "Allumé lorsque TCP est connecté",
            "on_when_powerfailuredetection_connected_andnofailure": "Allumé lorsque la détection de panne de courant est connectée et aucune panne",
            "activated_when_acousticsignaldevice_ison": "Activé lorsque le dispositif de signal sonore est allumé",
            "ledboom_controlling_green_leds": "Bras LED contrôlant les LED vertes",
            "ledboom_controlling_red_leds": "Bras LED contrôlant les LED rouges",
            "allow_exit_no_entry": "Autoriser la sortie sans entrée existante préalable",
            "allow_exit_no_entry_description": "Cette option est requise pour les stationnements qui on une sortie ou plus en mode libre sans validation.",
            "Validation failed": "Erreur de validation",
            "a customer": "un client",
            "a guest": "un visiteur",
            "a user": "un utilisateur",

            "Printer status changed": "L'état de l'imprimante a changé",
            "The printer device has connected.": "L'imprimante est connectée.",
            "The printer device has been disconnected.": "Le périphérique d'impression a été déconnecté.",
            "The printer device is online.": "L'imprimante est en ligne.",
            "The printer device is offline.": "L'imprimante est hors ligne.",
            "The printer device is waiting for online error recovery.": "L'imprimante attend la récupération d'erreur en ligne.",
            "The printer device has recovered.": "L'imprimante à récupérée.",
            "The printer device feed button has been pressed.": "Le bouton d'entraînement du papier de l'imprimante a été enfoncé.",
            "The printer device feed button has been released.": "Le bouton d'entraînement du papier de l'imprimante a été relâché.",
            "The printer plate has been opened.": "La plaque de l'imprimante a été ouverte.",
            "The printer plate has been closed.": "La plaque de l'imprimante a été fermée.",
            "The printer device is feeding by button.": "L'imprimante entraîne le papier à l'aide du bouton.",
            "The printer device is no longer feeding by button.": "L'imprimante n'entraîne plus le papier avec le bouton.",
            "The printer device sensor for paper end stop is active.": "Le capteur de fin de course du papier de l'imprimante est actif.",
            "The printer device sensor for paper end stop is inactive.": "Le capteur de fin de course du papier de l'imprimante est inactif.",
            "The printer device has occurred an error.": "Une erreur s'est produite au niveau de l'imprimante.",
            "The printer device has recovered from an error.": "L'imprimante a récupéré après une erreur.",
            "The printer device has occurred a mechanical error.": "Une erreur mécanique est survenue au niveau de l'imprimante.",
            "The printer device has recovered from a mechanical error.": "L'imprimante a récupéré d'une erreur mécanique.",
            "The printer device has occurred an auto cutter error.": "Une erreur de coupe automatique s'est produite au niveau de l'imprimante.",
            "The printer device has recovered from an auto cutter error.": "L'imprimante a récupéré d'une erreur de coupe automatique.",
            "The printer device has occurred an unrecoverable error.": "Une erreur irrécupérable s'est produite au niveau du périphérique d'impression.",
            "The printer device has recovered from an unrecoverable error.": "L'imprimante a récupéré d'une erreur irrécupérable.",
            "The printer device has occurred an auto recoverable error.": "L'imprimante a rencontré une erreur récupérable automatiquement.",
            "The printer device has recovered from an auto recoverable error.": "L'imprimante a récupéré d'une erreur récupérable automatiquement.",
            "The printer device has detected adequate paper.": "L'imprimante a détecté suffisamment de papier.",
            "The printer device has detected inadequate paper.": "L'imprimante a détecté un papier inadéquat.",
            "The printer device has detected paper near end.": "L'imprimante a détecté une fin de papier imminente.",
            "The printer device has undetected paper near end.": "L'imprimante a du papier non détecté presque à la fin.",
            "The printer device has detected paper.": "L'imprimante a détecté du papier.",
            "The printer device has detected missing paper.": "L'imprimante a détecté du papier manquant.",
            "The printer device has detected paper roll end.": "L'imprimante a détecté la fin du rouleau de papier.",
            "The printer device has undetected paper roll end.": "L'imprimante présente une fin de rouleau de papier non détectée.",
            "event": "Évenement",
            "Card scanned": "Carte d'accès scannée",
            "The card with number": "La carte d'accès avec le numéro",
            "has been scanned": "a été scanné",
            "Coupon scanned": "Code QR scannée",
            "The qr coupon with number": "Le code QR avec le numéro",
            "PIN submited": "PIN soumis",
            "The PIN with number": "Le code PIN avec le numéro",
            "has been submited": "a été soumis",
            "access card is not allowed to do entries": "Les cartes d'accès clients ne sont pas autorisés à faire des entrées"
        }
    }
});

const app = createApp(App)
.use(IonicVue)
.use(router)
.use(store)
.use(eventBus)
//@ts-ignore
.use(BootstrapVue3)
.use(i18n)
.use(VueApexCharts)
.use(VueObserveVisibility);

app.config.globalProperties.$messaging = firebaseMessaging

router.isReady().then(() => {
    app.mount('#app');
});
