// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import axios from 'axios';
//@ts-ignore
import process from 'process';
const API_URL = process.env.NODE_ENV == 'development' ? 'https://' + window.location.hostname + ':3000/v1/' : 'https://' + window.location.hostname + ':3000/v1/';
class AccessService
{
	get(id: string)
	{
		return axios.post(`${API_URL}access`,
		{
			id: id
		}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	list(customer_id: string | null, user_id: string | null, page: number, search: string)
	{
		return axios.post(`${API_URL}access/list`, {customer_id: customer_id == null ? null : customer_id.toString(), user_id: user_id == null ? null : user_id.toString(), page: page, search: search}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	insert(customer_id: string | null, user_id: string | null, max_entrance: number, limits: number, type: string, status: string, validation_date: string, expiration_date: string, between_hours_start: string, between_hours_end: string, allow_monday: boolean, allow_tuesday: boolean, allow_wednesday: boolean, allow_thursday: boolean, allow_friday: boolean, allow_saturday: boolean, allow_sunday: boolean, one_time_only: boolean, number_of_access: number, username: string, locale: string, zones: string, tags: string, has_subscription: boolean)
	{
		return axios.put(`${API_URL}access`, {
			customer_id,
			user_id,
			max_entrance,
			limits,
			type,
			status,
			validation_date,
			expiration_date,
			between_hours_start,
			between_hours_end,
			allow_monday,
			allow_tuesday,
			allow_wednesday,
			allow_thursday,
			allow_friday,
			allow_saturday,
			allow_sunday,
			one_time_only,
			number_of_access,
			username,
			locale,
			zones,
			tags,
			has_subscription
		}, { withCredentials: true }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	update(id: string, max_entrance: number, limits: number, status: string, validation_date: string, expiration_date: string, between_hours_start: string, between_hours_end: string, allow_monday: boolean, allow_tuesday: boolean, allow_wednesday: boolean, allow_thursday: boolean, allow_friday: boolean, allow_saturday: boolean, allow_sunday: boolean, one_time_only: boolean, username: string, locale: string, zones: string, tags: string, has_subscription: boolean)
	{
		return axios.patch(`${API_URL}access`, {
			id: id.toString(),
			max_entrance,
			limits,
			status,
			validation_date,
			expiration_date,
			between_hours_start,
			between_hours_end,
			allow_monday,
			allow_tuesday,
			allow_wednesday,
			allow_thursday,
			allow_friday,
			allow_saturday,
			allow_sunday,
			one_time_only,
			username,
			locale,
			zones,
			tags,
			has_subscription
		}, { withCredentials: true }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	delete(id: string)
	{
		//@ts-ignore
		return axios.delete(`${API_URL}access`, { withCredentials: true, data: { id: id.toString() } }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}
}
export default new AccessService();
