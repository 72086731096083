
	import {
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButton,
		IonButtons,
		IonLabel,
		IonItem,
		IonSelect,
		IonCheckbox,
		IonInput,
		IonSelectOption,
		modalController,
		toastController
	} from "@ionic/vue";
	import { defineComponent } from 'vue';
	import moment from 'moment-timezone';
	import Vue3Barcode from 'vue3-barcode'
	import { useI18n } from 'vue-i18n';
	import AccessService from '../../services/access.service';
	import ZoneService from '../../services/zone.service';
	import TagService from '../../services/tag.service';
	// import ThermalPrinter from '../../utils/Thermal.js'
	var translate;
	export default defineComponent({
		name: 'EditCustomer',
		components: { Vue3Barcode, IonHeader, IonTitle, IonToolbar, IonInput, IonLabel, IonItem, IonButton, IonButtons, IonSelect, IonSelectOption, IonCheckbox },
		props: ['isModal', 'access_card'],
		setup()
		{
			const closeModal = () => {
				modalController.dismiss();
			};
			const { t } = useI18n({
				inheritLocale: true,
				useScope: 'global'
			});
			translate = t;
			return { closeModal, t };
		},
		data()
		{
			return {
				selectedTab:         1,
				id:                  '',
				customer_id:         '',
				user_id:             '',
				username:            '',
				totp:                '',
				status:              '1',
				type:                '1',
				zones:               [],
				tags:                [],
				all_zones:           [],
				all_tags:            [],
				max_entrance:        1,
				limits:              0,
				validation_date:     moment().format('YYYY-MM-DD'),
				expiration_date:     moment().format('YYYY-MM-DD'),
				between_hours_start: '00:00',
				between_hours_end:   '00:00',
				allow_monday:        true,
				allow_tuesday:       true,
				allow_wednesday:     true,
				allow_thursday:      true,
				allow_friday:        true,
				allow_saturday:      true,
				allow_sunday:        true,
				one_time_only:       false,
				has_subscription:    '0',
				//@ts-ignore
				locale:              this.$root.$i18n.locale
			}
		},
		async mounted()
		{
			await this.loadZones();
			await this.loadTags();
			this.id                  = this.access_card.id;
			this.customer_id         = this.access_card.customer_id;
			this.user_id             = this.access_card.user_id;
			this.username            = this.access_card.username;
			this.totp                = this.access_card.totp;
			this.status              = this.access_card.status ? '1': '0';
			this.type 				 = this.access_card.type.toString();
			// let zones                = this.access_card.zones;
			// this.zones               = zones.reduce(function(r, e) { r[zones.indexOf(e)] = e; return r; }, []);
			this.zones               = this.access_card.zones == null ? [] : this.access_card.zones;
			this.tags                = this.access_card.tags == null ? [] : this.access_card.tags;
			//Temp fix to ionic not updating its labels correctly with ion-select
			// setTimeout(() => {
			// 	this.zones           = this.access_card.zones == null ? [] : this.access_card.zones;
			// }, 1250);
			this.max_entrance        = this.access_card.max_entrance;
			this.limits              = this.access_card.limits;
			this.validation_date     = moment(this.access_card.validation_date).format('YYYY-MM-DD');
			this.expiration_date     = moment(this.access_card.expiration_date).format('YYYY-MM-DD');
			this.between_hours_start = moment(this.access_card.between_hours_start).format('HH:mm');
			this.between_hours_end   = moment(this.access_card.between_hours_end).format('HH:mm');
			this.allow_monday 		 = this.access_card.allow_monday;
			this.allow_tuesday 		 = this.access_card.allow_tuesday;
			this.allow_wednesday 	 = this.access_card.allow_wednesday;
			this.allow_thursday 	 = this.access_card.allow_thursday;
			this.allow_friday 		 = this.access_card.allow_friday;
			this.allow_saturday 	 = this.access_card.allow_saturday;
			this.allow_sunday 		 = this.access_card.allow_sunday;
			this.one_time_only 		 = this.access_card.one_time_only;
			this.has_subscription    = this.access_card.has_subscription ? '1' : '0';
			this.locale 		     = this.access_card.locale;
		},
		methods: {
			async loadZones()
			{
				const response = await ZoneService.getForFilters();
				if (response.count > 0)
				{
					this.all_zones = response.zones;
				}
			},
			async loadTags()
			{
				const response = await TagService.getForFilters();
				if (response.count > 0)
				{
					this.all_tags = response.tags;
				}
			},
			async printCoupon()
			{
				try
				{
					//@ts-ignore
					const locale = this.$root.$i18n.locale;
					//@ts-ignore
					this.$root.$i18n.locale = this.locale;
					//@ts-ignore
					await this.$root.printer.PrintAccessCard({
						id: this.id.toString(),
						username: this.username,
						totp: this.totp,
						status: this.status,
						type: this.type,
						max_entrance: this.max_entrance,
						limits: this.limits,
						validation_date: this.validation_date,
						expiration_date: this.expiration_date,
						between_hours_start: this.between_hours_start,
						between_hours_end: this.between_hours_end,
						allow_monday: this.allow_monday,
						allow_tuesday: this.allow_tuesday,
						allow_wednesday: this.allow_wednesday,
						allow_thursday: this.allow_thursday,
						allow_friday: this.allow_friday,
						allow_saturday: this.allow_saturday,
						allow_sunday: this.allow_sunday,
						one_time_only: this.one_time_only
						//@ts-ignore
					}, this.$root.$i18n.locale);
					//@ts-ignore
					this.$root.$i18n.locale = locale;
				}
				catch(error)
				{
					console.error(error);
				}
			},
			async updateAccessCard()
			{
				if(this.type == '1') this.one_time_only = false;
				const response = await AccessService.update(
					this.id.toString(),
					this.max_entrance,
					this.limits,
					this.status,
					this.validation_date,
					this.expiration_date,
					this.between_hours_start,
					this.between_hours_end,
					this.allow_monday,
					this.allow_tuesday,
					this.allow_wednesday,
					this.allow_thursday,
					this.allow_friday,
					this.allow_saturday,
					this.allow_sunday,
					this.one_time_only,
					this.username,
					this.locale,
					JSON.stringify(this.zones),
					JSON.stringify(this.tags),
					parseInt(this.has_subscription) == 1
				);
				if(response.success)
				{
					//@ts-ignore
					this.$eventBus.emit('refresh_access_cards')
					this.closeModal();
				}
				else
				{
					var message = '';
					if (typeof response.error != 'undefined') {
						message = response.error;
					} else if(typeof response.errors != 'undefined' && typeof response.errors.message != 'undefined') {
						Object.keys(response.errors.message).forEach(key => {
							message += response.errors.message[key] + '.<br/>';
						});
					}
					const toast = await toastController
					.create({
						position: 'top',
						header: translate('access_card_creation_error'),
						message: translate('error') + ': ' + message,
						duration: 2000
					})
					return toast.present();
				}
			}
		},
		computed: {
			darkMode() {
				return document.body.classList.contains('dark');
			}
		}
	});
