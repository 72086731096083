import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"height":"100%","padding":"15px","overflow":"auto"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-content":"center","padding-top":"25px"} }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_vue3_barcode = _resolveComponent("vue3-barcode")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, {
          color: _ctx.darkMode ? 'dark' : ''
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, { style: {"color":"var(--ion-color-black)"} }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('edit_access_card')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "primary" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "danger",
                  fill: "outline",
                  onClick: _ctx.closeModal
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["color"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "floating" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('status')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    interface: "popover",
                    value: _ctx.status,
                    onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.status=$event.target.value)),
                    "ok-text": _ctx.t('SELECT.ok'),
                    "cancel-text": _ctx.t('SELECT.cancel')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select_option, { value: "0" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('inactive')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "1" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('active')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "ok-text", "cancel-text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "floating" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('has_subscription')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    interface: "popover",
                    value: _ctx.has_subscription,
                    onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.has_subscription=$event.target.value)),
                    "ok-text": _ctx.t('SELECT.ok'),
                    "cancel-text": _ctx.t('SELECT.cancel')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select_option, { value: "0" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('no')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "1" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('yes')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "ok-text", "cancel-text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "floating" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('max_vehicles_inside')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "number",
                    value: _ctx.max_entrance,
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.max_entrance=$event.target.value))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "floating" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('max_usages')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "number",
                    value: _ctx.limits,
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.limits=$event.target.value))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "floating" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('validation_date')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "date",
                    value: _ctx.validation_date,
                    onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validation_date=$event.target.value))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "floating" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('expiration_date')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "date",
                    value: _ctx.expiration_date,
                    onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.expiration_date=$event.target.value))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "floating" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('between_hours_start')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "time",
                    value: _ctx.between_hours_start,
                    onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.between_hours_start=$event.target.value))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "floating" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('between_hours_end')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "time",
                    value: _ctx.between_hours_end,
                    onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.between_hours_end=$event.target.value))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.type == '3')
        ? (_openBlock(), _createBlock(_component_b_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, {
                cols: "12",
                sm: "12",
                md: "6",
                lg: "6",
                xl: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "floating" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('card_key')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        type: "text",
                        value: _ctx.username,
                        onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.username=$event.target.value))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, {
                    class: "item-has-value",
                    position: "floating"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('zones')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    interface: "popover",
                    modelValue: _ctx.zones,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.zones) = $event)),
                    onIonChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.zones=$event.target.value)),
                    multiple: "",
                    "ok-text": _ctx.t('SELECT.ok'),
                    "cancel-text": _ctx.t('SELECT.cancel')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_zones, (zone) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: zone.id + '_short',
                          data: zone,
                          value: zone.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(zone.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["data", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "ok-text", "cancel-text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "6",
            lg: "6",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, {
                    class: "item-has-value",
                    position: "floating"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('tags')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    interface: "popover",
                    modelValue: _ctx.tags,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.tags) = $event)),
                    onIonChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.tags=$event.target.value)),
                    multiple: "",
                    "ok-text": _ctx.t('SELECT.ok'),
                    "cancel-text": _ctx.t('SELECT.cancel')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_tags, (tag) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: tag.id + '_short',
                          data: tag,
                          value: tag.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tag.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["data", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "ok-text", "cancel-text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "3",
            sm: "3",
            md: "3",
            lg: "3",
            xl: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('day_2')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.allow_monday = $event)),
                    modelValue: _ctx.allow_monday
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "3",
            sm: "3",
            md: "3",
            lg: "3",
            xl: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('day_3')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.allow_tuesday = $event)),
                    modelValue: _ctx.allow_tuesday
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "3",
            sm: "3",
            md: "3",
            lg: "3",
            xl: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('day_4')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_ctx.allow_wednesday = $event)),
                    modelValue: _ctx.allow_wednesday
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "3",
            sm: "3",
            md: "3",
            lg: "3",
            xl: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('day_5')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_ctx.allow_thursday = $event)),
                    modelValue: _ctx.allow_thursday
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "3",
            sm: "3",
            md: "3",
            lg: "3",
            xl: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('day_6')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_ctx.allow_friday = $event)),
                    modelValue: _ctx.allow_friday
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "3",
            sm: "3",
            md: "3",
            lg: "3",
            xl: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('day_7')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_ctx.allow_saturday = $event)),
                    modelValue: _ctx.allow_saturday
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "3",
            sm: "3",
            md: "3",
            lg: "3",
            xl: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('day_1')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_checkbox, {
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.allow_sunday = $event)),
                    modelValue: _ctx.allow_sunday
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.type != '1' && _ctx.type != '3')
            ? (_openBlock(), _createBlock(_component_b_col, {
                key: 0,
                cols: "12",
                sm: "12",
                md: "12",
                lg: "12",
                xl: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('one_time_only')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_checkbox, {
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (_ctx.one_time_only = $event)),
                        modelValue: _ctx.one_time_only
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ((_ctx.customer_id == null || _ctx.customer_id == '') && (_ctx.user_id == null || _ctx.user_id == ''))
            ? (_openBlock(), _createBlock(_component_b_col, {
                key: 1,
                cols: "12",
                sm: "12",
                md: "12",
                lg: "12",
                xl: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "floating" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('locale')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select, {
                        interface: "popover",
                        value: _ctx.locale,
                        onIonChange: _cache[21] || (_cache[21] = ($event: any) => (_ctx.locale=$event.target.value)),
                        "ok-text": _ctx.t('SELECT.ok'),
                        "cancel-text": _ctx.t('SELECT.cancel')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "fr" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('french')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "en" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('english')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "ok-text", "cancel-text"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.type == '0')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: 'https://qrgen.lexoh.com/?c=lexohparkingkey:' + _ctx.totp + '@' + _ctx.username,
                      width: 200,
                      style: {"width":"200px"}
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (_ctx.type == '1')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: 'https://qrgen.lexoh.com/?c=lexohparkingqr:' + _ctx.username,
                      width: 200,
                      style: {"width":"200px"}
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true),
                (_ctx.type == '2')
                  ? (_openBlock(), _createBlock(_component_vue3_barcode, {
                      key: 2,
                      value: _ctx.username,
                      width: 1,
                      height: 50,
                      format: "CODE128"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.type != '0' && _ctx.type != '3')
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn btn-secondary btn_custom",
            type: "button",
            onClick: _cache[22] || (_cache[22] = ($event: any) => {_ctx.printCoupon();}),
            style: {"width":"100%","margin":"20px 0px 0px 0px"}
          }, _toDisplayString(_ctx.t('print')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: "btn btn-secondary btn_custom",
        type: "button",
        onClick: _cache[23] || (_cache[23] = ($event: any) => {_ctx.updateAccessCard();}),
        style: {"width":"100%","margin":"20px 0px"}
      }, _toDisplayString(_ctx.t('save_access_card')), 1)
    ])
  ], 64))
}