
    import {
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        modalController,
        toastController,
        alertController,
        IonIcon,
        IonItem,
        IonLabel,
        IonInput,
        IonSelect,
        IonSelectOption
    } from "@ionic/vue";
    import { defineComponent } from "vue";
    import { calendar, personCircle, list, gitNetwork, cog, statsChartOutline } from 'ionicons/icons';
    import { useI18n }        from 'vue-i18n';
    import EditAccessPage     from '@/views/access/EditAccessPage.vue';
    import DeviceService      from '@/services/device.service';
    import CameraService      from '@/services/camera.service';
    import ZoneService        from '@/services/zone.service';
    import EventService       from '@/services/event.service';
    import AccessService      from '@/services/access.service';
    import CameraWebRTCPlayer from './CameraWebRTCPlayer.vue';
    import moment from 'moment-timezone';
    import Timezone from '@/utils/Timezone';
    let translate;
    export default defineComponent({
        name: "DeviceModal",
        components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, CameraWebRTCPlayer },
        props: ['device'],
        data()
        {
            return {
                selectedTab:            1,
                loadingCamera:      false,
                cameraMonitorId:        0,
                cameraSecret:          '',
                cameraUrl:             '',
                type:                  '',
                name:                  '',
                brand:                 '',
                model:                 '',
                port_trigger_open:     '',
                port_trigger_close:    '',
                port_keep_open:        '',
                port_signal_open:      '',
                port_signal_close:     '',
                port_signal_blocked:   '',
                port_signal_presence:  '',
                keepOpenUntil:        '0',
                all_zones:             [],
                zone_id:                0,
                barrier_type:           0,
                elka_settings: {
                    stay_open_time:               300,
                    prewarning_before_closing_time: 3,
                    prewarning_before_opening_time: 0,
                    relay_operating_modes:         {
                        0: '0',
                        1: '0',
                        2: '0',
                        3: '0',
                        4: '0',
                        5: '0',
                        6: '0',
                        7: '0',
                        8: '0',
                        9: '0',
                        10: '0',
                        11: '0',
                        12: '0',
                        13: '0',
                        14: '0'
                    }
                },
                events_page: 1,
                events_rows: 0,
                events_items: [],
                events_loaded: false,
                events_fields: [
                    {
                        key: 'date_at',
                        label: translate('date_at'),
                        sortable: true
                    },
                    {
                        key: 'name',
                        label: translate('event'),
                        sortable: true
                    },
                    {
                        key: 'description',
                        label: translate('description'),
                        sortable: true
                    },
                    {
                        key: 'access_card',
                        label: translate('access_card'),
                        sortable: true
                    }
                ],
            }
        },
        setup() {
            const closeModal = () => {
                modalController.dismiss();
            };
            const { t } = useI18n({
                inheritLocale: true,
                useScope: 'global'
            });
            const openAccessCard = async (accessCardId: any) => {
                const response = await AccessService.get(accessCardId);
                if (response.success) {
                    const modal = await modalController.create({
                        component: EditAccessPage,
                        cssClass: 'my-custom-class',
                        componentProps: {
                            access_card: response.access_card
                        }
                    });
                    return modal.present();
                }
            };
            translate = t;
            return { closeModal, calendar, personCircle, list, gitNetwork, cog, statsChartOutline, t, openAccessCard };
        },
        async mounted()
        {
            if (this.device.camera_id > 0) {
                this.loadingCamera = true;
                this.loadCamera(this.device.camera_id);
            }
            await this.loadZones();
            if (this.device.id > 0) {
                await this.loadEvents();
            }
            for(var i = 0; i < 14; i++) {
                //@ts-ignore
                this.elka_settings.relay_operating_modes[i] = '0';
            }
            this.type                 = this.device.type;
            this.name                 = this.device.name;
            this.brand                = this.device.brand;
            this.model                = this.device.model;
            this.zone_id              = this.device.zone_id == null              ? 0  : this.device.zone_id;
            this.port_trigger_open    = this.device.port_trigger_open == null    ? '' : this.device.port_trigger_open.toString();
            this.port_trigger_close   = this.device.port_trigger_close == null   ? '' : this.device.port_trigger_close.toString();
            this.port_keep_open       = this.device.port_keep_open == null       ? '' : this.device.port_keep_open.toString();
            this.port_signal_open     = this.device.port_signal_open == null     ? '' : this.device.port_signal_open.toString();
            this.port_signal_close    = this.device.port_signal_close == null    ? '' : this.device.port_signal_close.toString();
            this.port_signal_blocked  = this.device.port_signal_blocked == null  ? '' : this.device.port_signal_blocked.toString();
            this.port_signal_presence = this.device.port_signal_presence == null ? '' : this.device.port_signal_presence.toString();
            this.barrier_type         = this.device.barrier_type == null         ? 0  : this.device.barrier_type;
            if (this.device.elka_settings != null) {
                var settings = JSON.parse(this.device.elka_settings.toString());
                //@ts-ignore
                if (typeof settings.stay_open_time != 'undefined') {
                    this.elka_settings.stay_open_time = settings.stay_open_time;
                }
                //@ts-ignore
                if (typeof settings.prewarning_before_closing_time != 'undefined') {
                    this.elka_settings.prewarning_before_closing_time = settings.prewarning_before_closing_time;
                }
                //@ts-ignore
                if (typeof settings.prewarning_before_opening_time != 'undefined') {
                    this.elka_settings.prewarning_before_opening_time = settings.prewarning_before_opening_time;
                }
                //@ts-ignore
                if (typeof settings.relay_operating_modes != 'undefined') {
                    for(var i = 0; i < 14; i++) {
                        //@ts-ignore
                        this.elka_settings.relay_operating_modes[i] = settings.relay_operating_modes[i];
                    }
                }
            }
        },
        watch: {
            'events_page': {
                handler: function(newValue) {
                    // console.log('Current vaules:' + newValue.FirstName + ' ' + newValue.LastName);
                    this.loadEvents();
                },
                deep: true
            }
        },
        methods: {
            selectTab(index: number)
            {
                this.selectedTab = index;
            },
            async loadZones()
            {
                const response = await ZoneService.getForFilters();
                if (response.count > 0)
                {
                    this.all_zones = response.zones;
                }
            },
            async saveDeviceConfig()
            {
                const response = await DeviceService.editConfig(
                    this.device.id,
                    this.type,
                    this.name,
                    this.brand,
                    this.model,
                    this.zone_id,
                    this.barrier_type,
                    JSON.stringify(this.elka_settings)
                );
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('device_modified'),
                        message: translate('saved_configuration_successfully'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    var message = '';
                    if (typeof response.error != 'undefined') {
                        message = response.error;
                    } else if(typeof response.errors != 'undefined' && typeof response.errors.message != 'undefined') {
                        Object.keys(response.errors.message).forEach(key => {
                            message += response.errors.message[key] + '.<br/>';
                        });
                    }
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('device_modification_error'),
                        message: translate('error') + ': ' + message,
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async openGate()
            {
                const response = await DeviceService.openGate(this.device.id);
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_opening'),
                        message: translate('gate_successfully_opening'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_error'),
                        message: translate('cannot_open_gate'),
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async closeGate()
            {
                const response = await DeviceService.closeGate(this.device.id);
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_closing'),
                        message: translate('gate_successfully_closing'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_error'),
                        message: translate('cannot_close_gate'),
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async triggerKeepGateOpen()
            {
                const response = await DeviceService.keepGateOpen(this.device.id, parseInt(this.keepOpenUntil));
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_opening'),
                        message: translate('gate_successfully_opening'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_error'),
                        message: translate('cannot_open_gate'),
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async lostTicket()
            {
                const response = await DeviceService.lostTicket(this.device.id);
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_opening'),
                        message: translate('gate_successfully_opening'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_error'),
                        message: translate('cannot_open_gate'),
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async reboot()
            {
                const alert = await alertController
                .create({
                    cssClass: 'my-custom-class',
                    header: translate('reboot_device'),
                    message: translate('are_you_sure_reboot_device', { name: this.device.name}),
                    buttons: [
                        {
                            text: translate('cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            id: 'cancel-button'
                        },
                        {
                            text: translate('confirm'),
                            id: 'confirm-button',
                            handler: async () => {
                                const response = await DeviceService.reboot(this.device.id);
                                if(response.success)
                                {
                                    //@ts-ignore
                                    this.$eventBus.emit('refresh_devices')
                                    const toast = await toastController
                                    .create({
                                        position: 'top',
                                        header: translate('gate_rebooting'),
                                        message: translate('gate_successfully_rebooting'),
                                        duration: 2000
                                    })
                                    return toast.present();
                                }
                                else
                                {
                                    const toast = await toastController
                                    .create({
                                        position: 'top',
                                        header: translate('gate_error'),
                                        message: translate('cannot_reboot_gate'),
                                        duration: 2000
                                    })
                                    return toast.present();
                                }
                            }
                        }
                    ]
                });
                return alert.present();
            },
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            async loadCamera(id) {
                //@ts-ignore
                const response = await CameraService.get(id);
                if (response && response.success) {
                    const camera = response.camera;
                    this.cameraMonitorId = camera.id;
                    this.cameraSecret    = camera.secret;
                    this.loadingCamera   = false;
                    this.cameraUrl       = 'https://media.lexoh.com:52445/' + camera.monitorid + '/embed.html?realtime=true&autoplay=true&token=' + localStorage.getItem('token');
                }
            },
            async loadEvents() {
                //@ts-ignore
                const response = await EventService.list(this.events_page, '', [this.device.id]);
                if(response.success)
                {
                    this.events_items = response.events;
                    this.events_rows = response.count;
                    this.events_loaded = true;
                }
                else
                {
                    //something wrong... stop loading, show error with refresh button?
                }
            },
            formatDate(date: string)
            {
                //@ts-ignore
                return moment(date).utcOffset(Timezone.getTimezone(this.$store.state.auth.user)).format('YYYY-MM-DD HH:mm:ss');
            },
            translate_description(text: string)
            {
                text = text.replace('Gate has been opened by', translate('Gate has been opened by'));
                text = text.replace('in order to enter parking', translate('in order to enter parking'));
                text = text.replace('Gate has been opened in order to enter zone.', translate('Gate has been opened in order to enter zone.'));
                text = text.replace('Gate has been opened in order to exit zone.', translate('Gate has been opened in order to exit zone.'));
                text = text.replace('has logged in', translate('has_logged_in'));
				text = text.replace('Card scanned', translate('Card scanned'));
				text = text.replace('The card with number', translate('The card with number'));
				text = text.replace('has been scanned', translate('has been scanned'));
				text = text.replace('Coupon scanned', translate('Coupon scanned'));
				text = text.replace('The qr coupon with number', translate('The qr coupon with number'));
				text = text.replace('access card is not allowed to do entries', translate('access card is not allowed to do entries'));
				//printer
				text = text.replace('The printer device has connected.', translate('The printer device has connected.'));
				text = text.replace('The printer device has been disconnected.', translate('The printer device has been disconnected.'));
				text = text.replace('The printer device is online.', translate('The printer device is online.'));
				text = text.replace('The printer device is offline.', translate('The printer device is offline.'));
				text = text.replace('The printer device is waiting for online error recovery.', translate('The printer device is waiting for online error recovery.'));
				text = text.replace('The printer device has recovered.', translate('The printer device has recovered.'));
				text = text.replace('The printer device feed button has been pressed.', translate('The printer device feed button has been pressed.'));
				text = text.replace('The printer device feed button has been released.', translate('The printer device feed button has been released.'));
				text = text.replace('The printer plate has been opened.', translate('The printer plate has been opened.'));
				text = text.replace('The printer plate has been closed.', translate('The printer plate has been closed.'));
				text = text.replace('The printer device is feeding by button.', translate('The printer device is feeding by button.'));
				text = text.replace('The printer device is no longer feeding by button.', translate('The printer device is no longer feeding by button.'));
				text = text.replace('The printer device sensor for paper end stop is active.', translate('The printer device sensor for paper end stop is active.'));
				text = text.replace('The printer device sensor for paper end stop is inactive.', translate('The printer device sensor for paper end stop is inactive.'));
				text = text.replace('The printer device has occurred an error.', translate('The printer device has occurred an error.'));
				text = text.replace('The printer device has recovered from an error.', translate('The printer device has recovered from an error.'));
				text = text.replace('The printer device has occurred a mechanical error.', translate('The printer device has occurred a mechanical error.'));
				text = text.replace('The printer device has recovered from a mechanical error.', translate('The printer device has recovered from a mechanical error.'));
				text = text.replace('The printer device has occurred an auto cutter error.', translate('The printer device has occurred an auto cutter error.'));
				text = text.replace('The printer device has recovered from an auto cutter error.', translate('The printer device has recovered from an auto cutter error.'));
				text = text.replace('The printer device has occurred an unrecoverable error.', translate('The printer device has occurred an unrecoverable error.'));
				text = text.replace('The printer device has recovered from an unrecoverable error.', translate('The printer device has recovered from an unrecoverable error.'));
				text = text.replace('The printer device has occurred an auto recoverable error.', translate('The printer device has occurred an auto recoverable error.'));
				text = text.replace('The printer device has recovered from an auto recoverable error.', translate('The printer device has recovered from an auto recoverable error.'));
				text = text.replace('The printer device has detected adequate paper.', translate('The printer device has detected adequate paper.'));
				text = text.replace('The printer device has detected inadequate paper.', translate('The printer device has detected inadequate paper.'));
				text = text.replace('The printer device has detected paper near end.', translate('The printer device has detected paper near end.'));
				text = text.replace('The printer device has undetected paper near end.', translate('The printer device has undetected paper near end.'));
				text = text.replace('The printer device has detected paper.', translate('The printer device has detected paper.'));
				text = text.replace('The printer device has detected missing paper.', translate('The printer device has detected missing paper.'));
				text = text.replace('The printer device has detected paper roll end.', translate('The printer device has detected paper roll end.'));
				text = text.replace('The printer device has undetected paper roll end.', translate('The printer device has undetected paper roll end.'));
                //customer account
                text = text.replace('has created the customer account', translate('has_created_the_customer_account'));
                text = text.replace('has modified the customer account', translate('has_modified_the_customer_account'));
                text = text.replace('has deleted the customer account', translate('has_deleted_the_customer_account'));
                //access card
                text = text.replace('has created the access card', translate('has_created_the_access_card'));
                text = text.replace('has modified the access card', translate('has_modified_the_access_card'));
                text = text.replace('has deleted the access card', translate('has_deleted_the_access_card'));
                //Validation
                text = text.replace('MaxEntrancesReached', translate('MaxEntrancesReached'));
                text = text.replace('has already reached max entrances', translate('has already reached max entrances'));
                text = text.replace('DoesNotExists', translate('DoesNotExists'));
                text = text.replace('unable to find access card', translate('unable to find access card'));
                text = text.replace('access card limit has already been reached - Access card', translate('MaxEntrancesReached') + ' - ' + translate('access_card') + '#');
                //user account
                text = text.replace('has created the user account', translate('has_created_the_user_account'));
                text = text.replace('has modified the user account', translate('has_modified_the_user_account'));
                text = text.replace('has deleted the user account', translate('has_deleted_the_user_account'));
                //config
                text = text.replace('has modified the config', translate('has_modified_the_config'));
                text = text.replace('` from `', '` ' + translate('from') + ' `');
                text = text.replace('` to `', '` ' + translate('to') + ' `');
                //
                text = text.replace('has connected', translate('has_connected'));
                text = text.replace('has disconnected', translate('has_disconnected'));
                //
                text = text.replace('User `', translate('user') + ' `');
                text = text.replace('Customer `', translate('customer') + ' `');
                text = text.replace('Device `', translate('device') + ' `');
                text = text.replace('Guest has entered the parking.', translate('guest_entered_parking'));
                //signals
                text = text.replace('has manually sent stay open signal to the device', translate('has_sent_stay_open_signal'));
                text = text.replace('has manually sent close signal to the device', translate('has_sent_close_signal'));
                text = text.replace('has manually sent open signal to the device', translate('has_sent_open_signal'));
                text = text.replace('for a duration of', translate('for_a_duration_of'));
                text = text.replace('`indefinitely`', translate('_indefinitely_'));
                text = text.replace('received gate signal', translate('received_gate_signal'));
                text = text.replace('State has changed to', translate('state_changed_to'));
                text = text.replace('closed', translate('closed'));
                text = text.replace('opened', translate('opened'));
                text = text.replace('closing', translate('closing'));
                text = text.replace('opening', translate('opening'));
                text = text.replace('blocked', translate('blocked'));
                text = text.replace('max vehicules inside limit reached', translate('max vehicules inside limit reached'));
                //zones
                text = text.replace('has created the zone', translate('has_created_zone'));
                text = text.replace('has modified the zone', translate('has_modified_zone'));
                text = text.replace('has deleted the zone', translate('has_deleted_zone'));

                text = text.replace('Open', translate('open'));
                text = text.replace('Closed', translate('closed'));
                text = text.replace('Blocked', translate('blocked'));

                text = text.replace('a customer', translate('a customer'));
                text = text.replace('a guest', translate('a guest'));
                text = text.replace('a user', translate('a user'));

                text = text.replace('customer', translate('customer'));
                text = text.replace('guest', translate('guest'));
                text = text.replace('user', translate('user'));
                text = text.replace('Access card', translate('access_card'));
                text = text.replace('does not exists', translate('DoesNotExists'));
                return text;
            }
        },
        computed: {
            darkMode() {
                return document.body.classList.contains('dark');
            },
            isTechnician() {
                //@ts-ignore
                return this.$store.state.auth.user.role == 1;
            }
        }
    });
