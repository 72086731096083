// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import axios from 'axios';
//@ts-ignore
import process from 'process';
const API_URL = process.env.NODE_ENV == 'development' ? 'https://' + window.location.hostname + ':3000/v1/' : 'https://' + window.location.hostname + ':3000/v1/';

class TagService
{
	get(id: string)
	{
		return axios.post(`${API_URL}tag`,
		{
			id: id
		}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	list(page: number, search: string)
	{
		return axios.post(`${API_URL}tag/list`, { page: page < 1 ? 1 : page, search: search }, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	insert(name: string, color: string)
	{
		return axios.put(`${API_URL}tag`, {
			name,
			color
		}, { withCredentials: true }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	update(id: string, name: string, color: string)
	{
		return axios.patch(`${API_URL}tag`, {
			id: id.toString(),
			name,
			color
		}, { withCredentials: true }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	delete(id: string)
	{
		//@ts-ignore
		return axios.delete(`${API_URL}tag`, { withCredentials: true, data: { id: id.toString() } }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	getForFilters()
	{
		return axios.post(`${API_URL}tag/filters`, {}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}
}

export default new TagService();
